import React, { useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import {
  Mail,
  Phone,
  MapPin,
  Briefcase,
  Code,
  Moon,
  Sun,
  Menu,
  FileText,
  Users,
  Download
} from 'lucide-react';

const CV = () => {
  // Dark / Light Mode state
  const [isDark, setIsDark] = useState(false);
  // Dropdown Menu state
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Two refs: one for header + experience, one for skills
  const firstPartRef = useRef(null);
  const skillsRef = useRef(null);

  // Function to download PDF, split into two pages
  const handleDownloadPDF = async () => {
    try {
      // Create jsPDF (portrait, pt, A4)
      const pdf = new jsPDF({
        orientation: 'p',
        unit: 'pt',
        format: 'a4'
      });
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      // ----- CAPTURE PART 1 (Header + Experience) -----
      if (firstPartRef.current) {
        const canvas1 = await html2canvas(firstPartRef.current, {
          scale: 2, // better resolution
          backgroundColor: null
        });
        const imgData1 = canvas1.toDataURL('image/png');

        // Add image on page 1
        pdf.addImage(imgData1, 'PNG', 0, 0, pageWidth, 0);
      }

      // ----- ADD NEW PAGE (Page 2) -----
      pdf.addPage();

      // ----- CAPTURE PART 2 (Skills) -----
      if (skillsRef.current) {
        const canvas2 = await html2canvas(skillsRef.current, {
          scale: 2,
          backgroundColor: null
        });
        const imgData2 = canvas2.toDataURL('image/png');

        // Add image on page 2
        pdf.addImage(imgData2, 'PNG', 0, 0, pageWidth, 0);
      }

      // Save PDF file
      pdf.save('CV-LE-MINH-THUY.pdf');
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  // Tailwind classes for Dark/Light mode
  const theme = {
    bg: isDark ? 'bg-gray-900' : 'bg-gradient-to-br from-gray-50 to-white',
    card: isDark ? 'bg-gray-800/80' : 'bg-white',
    text: isDark ? 'text-white' : 'text-gray-900',
    mutedText: isDark ? 'text-gray-300' : 'text-gray-600',
    border: isDark ? 'border-gray-700' : 'border-gray-200',
    button: isDark ? 'bg-gray-700 hover:bg-gray-600' : 'bg-white hover:bg-gray-50',
    accent: isDark ? 'bg-blue-500/10 text-blue-300' : 'bg-blue-50 text-blue-600',
    dropdown: isDark ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200',
    dropdownHover: isDark ? 'hover:bg-gray-700' : 'hover:bg-gray-50'
  };

  // Dropdown Menu items
  const menuItems = [
    { label: 'CV', href: '#cv', icon: <Briefcase className="w-4 h-4" /> },
    { label: 'Cover Letter', href: '#cover-letter', icon: <FileText className="w-4 h-4" /> },
    { label: 'Portfolio', href: '#portfolio', icon: <FileText className="w-4 h-4" /> },
    { label: 'References', href: '#references', icon: <Users className="w-4 h-4" /> }
  ];

  return (
    <div className={`min-h-screen w-full ${theme.bg} transition-all duration-300 py-8 print:p-0 print:bg-white font-serif`}>
      <div className="max-w-3xl mx-auto px-4 space-y-6">
        {/* Header Controls */}
        <div className="flex justify-end items-center gap-3 print:hidden mb-6">
          {/* Dropdown Menu Trigger */}
          <div className="relative">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className={`p-2.5 rounded-full ${theme.button} shadow-md hover:scale-105 transition-all duration-300 relative z-20
                ${isMenuOpen ? 'rotate-180' : 'rotate-0'} transform
              `}
            >
              <Menu className={`h-[18px] w-[18px] ${theme.text}`} />
            </button>

            {/* Dropdown Menu */}
            <div
              className={`absolute right-0 mt-2 w-48 rounded-lg border shadow-lg ${theme.dropdown} z-20
                transform transition-all duration-300 origin-top
                ${isMenuOpen ? 'scale-y-100 opacity-100' : 'scale-y-0 opacity-0 pointer-events-none'}
              `}
            >
              <div className="py-1">
                {menuItems.map((item, index) => (
                  <a
                    key={index}
                    href={item.href}
                    className={`flex items-center gap-3 px-4 py-2.5 text-sm ${theme.text} ${theme.dropdownHover}
                      transition-all duration-200 transform hover:translate-x-1
                    `}
                    style={{
                      transitionDelay: `${index * 50}ms`,
                      opacity: isMenuOpen ? 1 : 0,
                      transform: isMenuOpen ? 'translateX(0)' : 'translateX(-1rem)'
                    }}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    {item.icon}
                    <span>{item.label}</span>
                  </a>
                ))}
              </div>
            </div>

            {/* Overlay to hide dropdown on outside click */}
            {isMenuOpen && (
              <div 
                className="fixed inset-0 z-10 bg-black/5 backdrop-blur-sm transition-opacity duration-300"
                onClick={() => setIsMenuOpen(false)}
              />
            )}
          </div>

          {/* Dark Mode Toggle */}
          <button
            onClick={() => setIsDark(!isDark)}
            className={`p-2.5 rounded-full ${theme.button} shadow-md hover:scale-105 transition-all duration-300 transform`}
          >
            {isDark ? (
              <Sun className={`h-[18px] w-[18px] ${theme.text}`} />
            ) : (
              <Moon className={`h-[18px] w-[18px] ${theme.text}`} />
            )}
          </button>

          {/* Download PDF Button - hidden on mobile */}
          <button
            onClick={handleDownloadPDF}
            className={`hidden md:flex p-2.5 rounded-full ${theme.button} shadow-md hover:scale-105 
              transition-all duration-300 transform print:hidden items-center gap-2
            `}
          >
            <Download className={`h-[16px] w-[16px] ${theme.text}`} />
            <span className={`text-sm ${theme.text}`}>Download PDF</span>
          </button>
        </div>

        {/* PART 1: Header + Work Experience (Page 1) */}
        <div ref={firstPartRef} className="space-y-6">
          {/* Header */}
          <div className={`${theme.card} rounded-lg shadow-md border ${theme.border} p-6 transition-all duration-300`}>
            <div className="flex flex-col md:flex-row justify-between items-start gap-6 md:items-center">
              <div className="space-y-1">
                <h1 className={`text-2xl font-bold ${theme.text}`}>
                  LE MINH THUY
                </h1>
                <h2 className={`text-lg ${theme.mutedText}`}>
                  Web Developer
                </h2>
              </div>
              <div className="flex flex-wrap items-center gap-4">
                <a
                  href="mailto:lmthuy37@gmail.com"
                  className={`flex items-center gap-2 ${theme.mutedText} hover:text-blue-500 transition-colors text-sm`}
                >
                  <Mail className="w-4 h-4 shrink-0" />
                  <span>lmthuy37@gmail.com</span>
                </a>
                <a
                  href="tel:+84367616667"
                  className={`flex items-center gap-2 ${theme.mutedText} hover:text-blue-500 transition-colors text-sm`}
                >
                  <Phone className="w-4 h-4 shrink-0" />
                  <span>+84 367616667</span>
                </a>
                <div className={`flex items-center gap-2 ${theme.mutedText} text-sm`}>
                  <MapPin className="w-4 h-4 shrink-0" />
                  <span>Ho Chi Minh City, Vietnam</span>
                </div>
              </div>
            </div>
          </div>

          {/* Work Experience */}
          <div id="cv" className={`${theme.card} rounded-lg shadow-md border ${theme.border} p-6 transition-all duration-300`}>
            <div className="flex items-center gap-3 mb-6">
              <div className={`p-2 rounded-lg ${theme.accent}`}>
                <Briefcase className="w-5 h-5" />
              </div>
              <h2 className={`text-xl font-bold ${theme.text}`}>
                Work Experience
              </h2>
            </div>

            <div className="space-y-6">
              {/* 1. Backend Developer | OTP SMS */}
              <div className={`rounded-lg border ${theme.border} p-5 transition-all duration-300`}>
                <div className="flex flex-col md:flex-row justify-between items-start gap-4">
                  <div className="space-y-4 w-full">
                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className={`text-lg font-semibold ${theme.text}`}>
                          Backend Developer | OTP SMS
                        </h3>
                        <p className={`text-sm ${theme.mutedText} mt-1`}>
                          Sep 2023 - Present
                        </p>
                      </div>
                    </div>
                    <ul className={`list-disc ml-4 text-sm ${theme.mutedText} space-y-2`}>
                      <li>Built APIs with Golang (Gin) for generating OTP and integrating SMS services.</li>
                      <li>Implemented caching solutions with Redis to optimize user contact processing.</li>
                      <li>Managed deployment pipelines using GitLab CI/CD, Jenkins, ArgoCD, and Docker.</li>
                      <li>Deployed scalable solutions in Kubernetes (K8s).</li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* 2. Backend Developer | MEDIPAY */}
              <div className={`rounded-lg border ${theme.border} p-5 transition-all duration-300`}>
                <div className="flex flex-col md:flex-row justify-between items-start gap-4">
                  <div className="space-y-4 w-full">
                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className={`text-lg font-semibold ${theme.text}`}>
                          Backend Developer | MEDIPAY
                        </h3>
                        <p className={`text-sm ${theme.mutedText} mt-1`}>
                          (Duration: --)
                        </p>
                      </div>
                    </div>
                    <ul className={`list-disc ml-4 text-sm ${theme.mutedText} space-y-2`}>
                      <li>Developed APIs using Golang (Gin) for asynchronous user onboarding via AWS SQS.</li>
                      <li>Enhanced database performance with indexing and partitioning on PostgreSQL.</li>
                      <li>Built CI/CD pipelines with GitLab, Jenkins, ArgoCD, and containerized applications with Docker.</li>
                      <li>Deployed in Kubernetes (K8s) environments for efficient scaling.</li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* 3. Backend Developer | HDSaison */}
              <div className={`rounded-lg border ${theme.border} p-5 transition-all duration-300`}>
                <div className="flex flex-col md:flex-row justify-between items-start gap-4">
                  <div className="space-y-4 w-full">
                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className={`text-lg font-semibold ${theme.text}`}>
                          Backend Developer | HDSaison
                        </h3>
                        <p className={`text-sm ${theme.mutedText} mt-1`}>
                          Mar 2023 - Present
                        </p>
                      </div>
                    </div>
                    <ul className={`list-disc ml-4 text-sm ${theme.mutedText} space-y-2`}>
                      <li>Designed APIs for user onboarding with Golang (Gin), integrated with AWS SQS for asynchronous processing.</li>
                      <li>Improved database query performance by employing advanced indexing techniques.</li>
                      <li>Utilized CI/CD tools like GitLab, Jenkins, and ArgoCD for streamlined deployments.</li>
                      <li>Deployed services in containerized environments using Docker and Kubernetes.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* PART 2: Skills => Page 2 */}
        <div ref={skillsRef} className="space-y-6">
          <div className={`${theme.card} rounded-lg shadow-md border ${theme.border} p-6 transition-all duration-300`}>
            <div className="flex items-center gap-3 mb-6">
              <div className={`p-2 rounded-lg ${theme.accent}`}>
                <Code className="w-5 h-5" />
              </div>
              <h2 className={`text-xl font-bold ${theme.text}`}>
                Skills
              </h2>
            </div>

            <div className="space-y-6">
              {/* Technical Skills */}
              <div className={`rounded-lg border ${theme.border} p-5 transition-all duration-300`}>
                <div className="flex flex-col md:flex-row justify-between items-start gap-4">
                  <div className="space-y-4 w-full">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-3">
                        <div className={`p-2 rounded-lg ${theme.accent}`}>
                          <Code className="w-5 h-5" />
                        </div>
                        <h3 className={`text-lg font-semibold ${theme.text}`}>
                          Technical Skills
                        </h3>
                      </div>
                    </div>
                    <div className="flex flex-wrap gap-2">
                      {[
                        'Golang',
                        'JavaScript',
                        'Gin (Golang)',
                        'NodeJS',
                        'ReactJS',
                        'HTML',
                        'CSS',
                        'PostgreSQL',
                        'MongoDB',
                        'MySQL',
                        'MSSQL',
                        'AWS (EC2, SQS, Lambda, RDS)',
                        'Docker',
                        'Kubernetes',
                        'GitLab CI/CD'
                      ].map((skill) => (
                        <span
                          key={skill}
                          className={`px-3 py-1 rounded-full text-sm ${theme.accent} transition-colors`}
                        >
                          {skill}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              {/* Soft Skills */}
              <div className={`rounded-lg border ${theme.border} p-5 transition-all duration-300`}>
                <div className="flex flex-col md:flex-row justify-between items-start gap-4">
                  <div className="space-y-4 w-full">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-3">
                        <div className={`p-2 rounded-lg ${theme.accent}`}>
                          <Users className="w-5 h-5" />
                        </div>
                        <h3 className={`text-lg font-semibold ${theme.text}`}>
                          Soft Skills
                        </h3>
                      </div>
                    </div>
                    <div className="flex flex-wrap gap-2">
                      {[
                        'Communication',
                        'Teamwork',
                        'Problem Solving',
                        'English (B1)',
                        'Vietnamese (Native)'
                      ].map((skill) => (
                        <span
                          key={skill}
                          className={`px-3 py-1 rounded-full text-sm ${theme.accent} transition-colors`}
                        >
                          {skill}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* (Optional) Add Education, Projects... => still on page 2 */}
        </div>
      </div>
    </div>
  );
};

export default CV;
